import { Box, Button, CircularProgress, IconButton, ListItem, ListItemText, ThemeProvider, Typography, createTheme } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import NavBar from "../main/navbar/Navbar";
import AddTooltip from "../main/addTooltip/AddTooltip";
import '@inovua/reactdatagrid-enterprise/index.css';
import PopUp from "../main/popUp/popUp";
import { ckeckAgPassword, postAgOrder } from "../services/orderService";
import { getUsers, postUsers } from "../services/usersService";
import PPNavigation from "../main/ppNavigation/PPNavigation";
import bgImage from '../images/support_system_bg_mafi_technologies.jpg';
import {
  CellRenderObject
} from '@inovua/reactdatagrid-community/Layout/ColumnLayout/Cell/CellProps';
import EmailIcon from '@mui/icons-material/Email';
import EditIcon from '@mui/icons-material/Edit';
import { utils, writeFile } from "xlsx";
import PopUpItems from "../main/popUp/popUpItems";
import moment from 'moment/moment';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-enterprise/index.css'
import PopUpSend from "../main/popUp/popUpSend";

const gridStyle = {
    height: '100%',
    width: '50%',
}

const onLoading = () => {
  return (
    <Box className="w-full h-full flex items-center justify-center mt-4">
      <CircularProgress color="warning" />
    </Box>
  )
}

const SaltexListUsersAg = () => {

    const [gridRef, setGridRef] = useState(null);
    const [isEditable, setIsEditable] = useState(true);
    const [openModal, setOpenModal] = useState(false)
    const [openItemsModal, setOpenItemsModal] = useState(false)
    const [mode, setMode] = useState("light");
    const [list, setList] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [selectedRowData, setSelectedRowData] = useState(null)
    const [itemsData, setItemsData] = useState([]);
    const [usersData, setUsersData] = useState([]);
    let cBefore =  'Created date is before...';
    let cAfter = 'Created date is after...';
    window.moment = moment

    useEffect(() => {
      getUserCallback()
    }, [])

    const getUserCallback = () => {
      getUsers().then((data) => {
        console.log('getUserCallback')
        console.log(data)
        setUsersData(data)
        setIsLoading(false)
      })
    }

    const darkTheme = createTheme({
      palette:{
        mode: mode
      }
    });

    const sendEmail = useCallback((rowData, columnId, value) => {
      let userAg = JSON.parse(localStorage.getItem('user_new'));
      console.log('toco en paymentConfirmed');
      console.log(rowData);

      setSelectedRowData(rowData);
      handleModal(true);
    }, [usersData]);
    

    const filterValue = [
      { name: 'cli_buzon', operator: 'contains', type: 'string', value: '', headerProps: {
        style: {
          color: 'white',
          backgroundColor: '#000722bd',
          fontFamily: 'Montserrat-Light',
        }
      } },
      { name: 'cli_email', operator: 'contains', type: 'string', value: '' },
      { name: 'Cli_nombre', operator: 'contains', type: 'string', value: '' },
      { name: 'cli_tel', operator: 'contains', type: 'string', value: '' },
    ];

    const columns = [
        { name: 'cli_buzon', header: 'Buzon', editable: false },
        { name: 'Cli_nombre', header: 'Nombre' },
        { name: 'cli_email', header: 'Email' },
        { name: 'cli_tel', header: 'Telefono' },
        {
          name: 'cli_send', header: 'Enviar Info',
          render: ({ value, data }) => {
            return (
              <Box style={{ textAlign: 'center' }}>
                <EmailIcon
                  onClick={() => sendEmail(data)}
                  sx={{ cursor: 'pointer' }}
                  color="success"
                />
              </Box>
            );
          },
        },
        {
          name: 'edit', header: 'Edit', maxWidth: 200, defaultFlex: 1, render: ({ value, cellProps }) => {
            return (
              <Box className='flex flex-row justify-around'>
                {value}
                <Box>
                  <IconButton onClick={() => {
                    gridRef?.current?.startEdit?.({ rowIndex: cellProps.rowIndex ?? 0, columnId: 'Cli_nombre' });
                  }}>
                    <EditIcon /* sx={{color:'#fff'}} */ />
                  </IconButton>
                </Box>
              </Box>
            )
          }
        },
      ];

    const handleModal = (boolean) => {
      setOpenModal(boolean);
    };

    const handleItemsModal = (boolean) => {
      setOpenItemsModal(boolean);
    };

    const onUserEditComplete = useCallback(({ value, columnId, rowIndex }) => {
      console.log('onUserEditComplete')
      let newData = [...usersData];
      // Actualiza la celda editada
      newData[rowIndex][columnId] = value;
  
      // Aquí puedes acceder a toda la data de la fila seleccionada
      const editedRowData = newData[rowIndex];
      const editedPostData = { ...editedRowData };
      postUsers(editedPostData)
    
    }, [usersData]);

    return (
      <ThemeProvider theme={darkTheme}>
      <Box className="h-full">
        <NavBar />
        <Box className="w-full flex h-full">
          <PPNavigation />
          {isLoading ? onLoading() : (
          <Box className="w-full h-full flex flex-col justify-center items-center" sx={{backgroundImage: 'url(' + bgImage + ')', backgroundSize: '100%'}}>
            <Box className="w-2/3 flex justify-center items-center mt-4">
              <Box className="w-1/3 flex items-center justify-center">
                <Typography fontSize={20} >Lista de usuarios</Typography>
              </Box>
            </Box>
            <Box className="w-4/5 h-full flex justify-center items-start mt-4" > 
                <ReactDataGrid
                    className="w-2/3"
                    idProperty="id"
                    style={gridStyle}
                    columns={columns}
                    dataSource={usersData}
                    showZebraRows={true}
                    showEmptyRows={false}
                    defaultFilterValue={filterValue}
                    sortable
                    pagination
                    editable={isEditable}
                    onReady={setGridRef}
                    onEditComplete={onUserEditComplete}
                />
            </Box>
        </Box>
        )}
        </Box>
        <AddTooltip />
        <PopUpSend handleOpen={handleModal} open={openModal} typeOperation={'enviar'} data={selectedRowData} />
        <PopUpItems handleOpen={handleItemsModal} open={openItemsModal} data={itemsData}/>
      </Box>
      </ThemeProvider>
    );
}

export default SaltexListUsersAg;