import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import styled from "@emotion/styled";
import Modal from '@mui/material/Modal';
import YouTube from 'react-youtube';

const SaltexLogo = styled('img')(({theme}) => ({
  [theme.breakpoints.up("sm")]: {
    width: '30%',
  },
  [theme.breakpoints.down("sm")]: {
    width: '50%', // Ajustado para pantallas más pequeñas
  },
  p: 20
}));

const responsiveModalStyle = theme => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%', // Usar un porcentaje para la anchura
  maxWidth: '700px', // Máxima anchura para pantallas grandes
  bgcolor: 'background.paper',
  borderRadius: 5,
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  overflowY: 'auto', // Añadir scroll si es necesario
});

const PopUpLogin = ({ open, handleOpen, data }) => {

  const opts = {
    width: '100%',
    height: '400px',
    playerVars: {
      autoplay: 1,
    },
  };

  const handleClose = () => handleOpen(false);

  useEffect(() => {
    console.log('PopUpLogin')
    console.log('data ', data)
  }, [data])

  const renderContent = () => {
    if (data && data.length > 0) {
      const popup = data[0];
      if (popup.popup_type === 1) {
        const videoId = new URL(popup.popup_url).searchParams.get('v');
        return <YouTube videoId={videoId} opts={opts} />;
      } else if (popup.popup_type === 2) {
        return <img src={popup.popup_url} alt="Popup" style={{ width: '100%', maxHeight: '400px', objectFit: 'contain' }} />;
      }
    }
    return null;
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={responsiveModalStyle}>
          <SaltexLogo src="assets/logos/logo_company_dark.png" alt="logo" />
          <Box className="w-full">
            {renderContent()}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default PopUpLogin;
