import { Box, Button, CircularProgress, IconButton, ListItem, ListItemText, ThemeProvider, Typography, createTheme } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import NavBar from "../main/navbar/Navbar";
import AddTooltip from "../main/addTooltip/AddTooltip";
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import '@inovua/reactdatagrid-enterprise/index.css';
import {
  CellRenderObject
} from '@inovua/reactdatagrid-community/Layout/ColumnLayout/Cell/CellProps';
import { TypeComputedProps, TypeEditInfo } from '@inovua/reactdatagrid-community/types';
import PopUp from "../main/popUp/popUp";
import { postAgOrder, postCliOrder } from "../services/orderService";
import PopUpItems from "../main/popUp/popUpItems";
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import PPNavigation from "../main/ppNavigation/PPNavigation";
import bgImage from '../images/support_system_bg_mafi_technologies.jpg';
import { utils, writeFile } from 'xlsx';

import moment from 'moment/moment';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-enterprise/index.css'
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter'
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter'
import DateFilter from '@inovua/reactdatagrid-community/DateFilter'

const gridStyle = {
    height: '100%',
    width: '50%',
}

const filterUsers = [
  {
    name: 'name', operator: 'contains', type: 'string', value: '', headerProps: {
      style: {
        color: 'white',
        backgroundColor: '#000722bd',
        fontFamily: 'Montserrat-Light',
      }
    }
  },
]

const onLoading = () => {
  return (
    <Box className="w-full h-full flex items-center justify-center mt-4">
      <CircularProgress color="warning" />
    </Box>
  )
}

const SaltexListComprasCli = () => {

    const [gridRef, setGridRef] = useState(null);
    const [isEditable, setIsEditable] = useState(true);
    const [openModal, setOpenModal] = useState(false)
    const [mode, setMode] = useState("light");
    const [list, setList] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [itemsData, setItemsData] = useState([]);
    let cBefore =  'Created date is before...';
    let cAfter = 'Created date is after...';
    window.moment = moment

    useEffect(() => {
      postCliOrder().then((response) => {
        console.log('postCliOrder')
        for(let i=0; i < response.length; i++){
          let vCompra = response[i].compra_valor;
          response[i].compra_valor = parseInt(vCompra).toLocaleString();
        }
        console.log(response)
        setList(response)
        setIsLoading(false)
      })
    }, [])

    const darkTheme = createTheme({
      palette:{
        mode: mode
      }
    });

    const filterValue = [
      { name: 'compra_id', operator: 'contains', type: 'string', value: '' },
      { name: 'cli_buzon', operator: 'contains', type: 'string', value: '', headerProps: {
        style: {
          color: 'white',
          backgroundColor: '#000722bd',
          fontFamily: 'Montserrat-Light',
        }
      } },
      {
        name: 'fecha_pagada',
        operator: 'inrange',
        type: 'date',
        value: ''
      },
      { name: 'cli_nombre', operator: 'contains', type: 'string', value: '' },
      { name: 'cli_tel', operator: 'contains', type: 'string', value: '' },
      { name: 'compra_pagada', operator: 'inlist', type: 'select', value: [0, 1] },
      { name: 'compra_valor', operator: 'contains', type: 'string', value: '' },
      { name: 'edit', operator: 'contains', type: 'string', value: '' }
    ];

    const columns = [
      { name: 'compra_id', header: 'ID' },
      { name: 'cli_buzon', header: 'Buzon' },
      { name: 'cli_nombre', header: 'Nombre' },
      { name: 'cli_tel', header: 'Telefono' },
      { name: 'compra_pagada',
        header: 'Pagada', filterEditor: SelectFilter,
        filterEditorProps: {
          multiple: true,
          wrapMultiple: false,
          dataSource: [
            { 
              id: 1, 
              label: <CheckIcon color="success" />, // Ícono para "1"
            },
            { 
              id: 0, 
              label: <CloseIcon color="error" />, // Ícono para "0"
            },
          ],
        },
        render: ({ value }) => {
          return (
            <Box sx={{ textAlign: 'center' }}>
              {value === 1 ? <CheckIcon color="success" /> : <CloseIcon color="error" />}
            </Box>
          );
        },
      },
      {
        name: 'fecha_pagada',
        header: 'Fecha Pagada',
        defaultFlex: 1,
        minWidth: 200,
        filterEditor: DateFilter,
        filterEditorProps: (props, { index }) => {
          // for range and notinrange operators, the index is 1 for the after field
  
          return {
            dateFormat: 'MM-DD-YYYY',
            cancelButton: false,
            highlightWeekends: false,
            placeholder: index == 1 ? cBefore : cAfter
          }
        },
        render: ({ value, cellProps }) => {
          return moment(value).format('MM-DD-YYYY')
        }
      },
      {
        name: 'compra_valor',
        header: 'Valor Total',
        render: ({ value }) => {
          // Convertir el valor a un string para facilitar la manipulación
          const stringValue = value.toString();
      
          // Formatear el valor con puntos cada tres cifras
          // Esta expresión regular agrega un punto cada tres dígitos, excepto al principio del número
          const formattedValue = stringValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      
          return <Box sx={{ textAlign: 'right' }}>${formattedValue}</Box>;
        },
      },
      {
        name: 'edit', header: 'Ver Productos',
        render: ({ value, data }) => {
          return (
            <Box style={{ textAlign: 'center' }}>
              <LocalGroceryStoreIcon
                onClick={() => showItemsModal(data)}
                sx={{ cursor: 'pointer' }}
                color="success"
              />
            </Box>
          );
        },
      },
    ];
      
      const rows = [
        { id: 0, items: 'Example', cantidad: 1, url: 'www.amazon.com', estado: 'Pago' },
      ];

    const handleModal = (boolean) => {
        setOpenModal(boolean);
    };

    const showItemsModal = (rowData) => {
      console.log('showItemsModal', rowData);
    
      // Usa el compra_id de los datos de fila para encontrar los ítems
      const items = list.find(item => item.compra_id === rowData.compra_id)?.items;
    
      if (items) {
        setItemsData(items);
        handleModal(true);
      }
    };

    const onWriteCsv = async () => {
      const book = utils.book_new();
      const sheet = utils.json_to_sheet(list);
      utils.book_append_sheet(book, sheet, 'compras');
  
      await writeFile(book, 'compras.xlsx');
    }

    return (
      <ThemeProvider theme={darkTheme}>
      <Box className="h-full">
        <NavBar />
        <Box className="w-full flex h-full">
          <PPNavigation />
        {isLoading ? onLoading() : (
          <Box className="w-full h-full flex flex-col justify-center items-center" sx={{backgroundImage: 'url(' + bgImage + ')', backgroundSize: '100%'}}>
            <Box className="w-2/3 flex justify-center items-center mt-4">
              <Box className="w-1/3 flex items-center justify-center">
                <Typography fontSize={20} >Mis Compras</Typography>
              </Box>
            </Box>
            <Box className="w-2/3 flex justify-end items-center">
              <Button sx={{
                  backgroundColor: "#f9b22b",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#e09d21",
                  },
                  margin: "0.5rem 0",
                }} onClick={onWriteCsv}>
                  Exportar a Excel
                </Button>
            </Box>
            <Box className="w-4/5 h-full flex justify-center items-start mt-4" >
                <ReactDataGrid
                    className="w-full"
                    idProperty="id"
                    style={gridStyle}
                    columns={columns}
                    dataSource={list}
                    showZebraRows={true}
                    showEmptyRows={false}
                    defaultFilterValue={filterValue}
                    sortable
                    pagination
                    editable={isEditable}
                    onReady={setGridRef}
                /*  onEditComplete={activeSection === 'organizations' ? onEditCompleteOrg || [] : activeSection === 'users' ? onEditCompleteUsers || [] : onEditCompleteUsers || []} */
                />
            </Box>
        </Box>
        )}
        </Box>
        {/* <AddTooltip /> */}
        <PopUpItems handleOpen={handleModal} open={openModal} data={itemsData}/>
      </Box>
      </ThemeProvider>
    );
}

export default SaltexListComprasCli;