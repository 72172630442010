import React from 'react';
import { Box, Modal } from '@mui/material';
import tabla from '../../images/tabla_talles.jpg';

const SizeGuideModal = ({ isOpen, handleClose }) => {
    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
            className="flex justify-center items-center"
            // otros props que necesites
        >
            <Box className="w-full md:w-2/3 h-auto">
                {/* Contenido del Modal */}
                <img src={tabla} alt="Guía de Tallas" />
            </Box>
        </Modal>
    );
};

export default SizeGuideModal;