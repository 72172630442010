import { Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useTranslation } from 'react-i18next';

const PPNavList = () =>{
    const { t } = useTranslation();
    const user = JSON.parse(localStorage.getItem("user_new"));
    const [isAdminContent, setIsAdminContent] = useState('');

    useEffect(()=>{
        if(user.user_id == 91 && user.agencia_id == 1){
            setIsAdminContent(
                <>
                    <Divider /> 
                    <List>
                        <ListItem>
                            <h6 className='text-center text-slate-500 text-xl'>
                                {t('Bienvenido')}
                            </h6>
                        </ListItem>

                        <ListItem className="-mt-4">
                            <h6 className="text-slate-500 text-xs">
                                {user.user_nombre}
                            </h6>
                        </ListItem> 

                        <ListItem className="-mt-4">
                            <h6 className="text-slate-500 text-xs">
                                {user.agencia_nombre}
                            </h6>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton component="a" href="/list_admin">
                                <ListItemIcon >
                                    <DashboardIcon className="text-slate-500"/>
                                </ListItemIcon>
                                <ListItemText primary={t('Compras')} className="text-slate-500" />
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton component="a" href="/trm">
                                <ListItemIcon >
                                    <DashboardIcon className="text-slate-500"/>
                                </ListItemIcon>
                                <ListItemText primary={t('Configurar TRM')} className="text-slate-500" />
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton component="a" href="/pop_up">
                                <ListItemIcon >
                                    <DashboardIcon className="text-slate-500"/>
                                </ListItemIcon>
                                <ListItemText primary={t('Configurar Pop Up')} className="text-slate-500" />
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton component="a" href="/">
                                <ListItemIcon >
                                    <DashboardIcon className="text-slate-500"/>
                                </ListItemIcon>
                                <ListItemText primary={t('Logout')} className="text-slate-500" />
                            </ListItemButton>
                        </ListItem>
                    </List>
                    </>
            );
            
        } else if((user.user_id !== 91 && user.agencia_id !== 1) && user.cli_id == null){
            setIsAdminContent(
            <>
                    <Divider /> 
                    <List>
                        <ListItem>
                            <h6 className='text-center text-slate-500 text-xl'>
                                {t('Bienvenido Agencia')}
                            </h6>
                        </ListItem>

                        <ListItem className="-mt-4">
                            <h6 className="text-slate-500 text-xs">
                                {user.user_nombre}
                            </h6>
                        </ListItem> 
                        
                        <ListItem className="-mt-4">
                            <h6 className="text-slate-500 text-xs">
                                {user.agencia_nombre}
                            </h6>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton component="a" href="/list_ag">
                                <ListItemIcon >
                                    <DashboardIcon className="text-slate-500"/>
                                </ListItemIcon>
                                <ListItemText primary={t('Compras')} className="text-slate-500" />
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton component="a" href="/list_users_ag">
                                <ListItemIcon >
                                    <DashboardIcon className="text-slate-500"/>
                                </ListItemIcon>
                                <ListItemText primary={t('Lista de Usuarios')} className="text-slate-500" />
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton component="a" href="/">
                                <ListItemIcon >
                                    <DashboardIcon className="text-slate-500"/>
                                </ListItemIcon>
                                <ListItemText primary={t('Logout')} className="text-slate-500" />
                            </ListItemButton>
                        </ListItem>
                    </List>
            </>
            );
        } else if(user.cli_id != null) {
            setIsAdminContent(
                <>
                        <Divider /> 
                        <List>
                            <ListItem>
                                <h6 className='text-center text-slate-500 text-xl'>
                                    {t('Bienvenido Cliente')}
                                </h6>
                            </ListItem>
                            <ListItem className="-mt-4">
                                <h6 className="text-slate-500 text-xs">
                                    {user.cli_nombre}
                                </h6>
                            </ListItem> 
                            <ListItem className="-mt-4">
                                <h6 className="text-slate-500 text-xs">
                                    {user.cli_buzon}
                                </h6>
                            </ListItem>
                            <ListItem className="-mt-4">
                                <h6 className="text-slate-500 text-xs">
                                    {user.cuenta_nombre}
                                </h6>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton component="a" href="/Dashboard">
                                    <ListItemIcon >
                                        <DashboardIcon className="text-slate-500"/>
                                    </ListItemIcon>
                                    <ListItemText primary={t('Busqueda')} className="text-slate-500" />
                                </ListItemButton>
                            </ListItem>
    
                            <ListItem disablePadding>
                                <ListItemButton component="a" href="/list_cli">
                                    <ListItemIcon >
                                        <DashboardIcon className="text-slate-500"/>
                                    </ListItemIcon>
                                    <ListItemText primary={t('Mis Compras')} className="text-slate-500" />
                                </ListItemButton>
                            </ListItem>

                            <ListItem disablePadding>
                                <ListItemButton component="a" href="/cart">
                                    <ListItemIcon >
                                        <DashboardIcon className="text-slate-500"/>
                                    </ListItemIcon>
                                    <ListItemText primary={t('Mi Carrito')} className="text-slate-500" />
                                </ListItemButton>
                            </ListItem>
    
                            <ListItem disablePadding>
                                <ListItemButton component="a" href="/">
                                    <ListItemIcon >
                                        <DashboardIcon className="text-slate-500"/>
                                    </ListItemIcon>
                                    <ListItemText primary={t('Logout')} className="text-slate-500" />
                                </ListItemButton>
                            </ListItem>
                        </List>
                </>
            );
        }

    },[])

    return(
        <Box>
            {isAdminContent}
        </Box>
    );
}

export default PPNavList;