import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Input from '@mui/material/Input';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { ckeckAgPassword } from '../../services/orderService';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const PopUp = ({ open, handleOpen, typeOperation, data, getData, postData }) => {
  let userAgString = localStorage.getItem('user_new');
  let userAg = JSON.parse(userAgString);
  const [password, setPassword] = useState('');
  const [confirmation, setConfirmation] = useState(false);
  const [msgConfirm, setMsgConfirm] = useState('');

  const handleClose = () => handleOpen(false);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmationChange = () => {
    setConfirmation(!confirmation);
  };

  const handlePaidButtonClick = () => {
    postData(password, data.compra_id).then((response) => {
      console.log('handlePaidButtonClick')
      console.log(response)
      getData()
      handleClose()
    })
  };

  useEffect(() => {

    if (open) {
      setPassword('');
      setConfirmation(false);
    }
    
    if(data){
      if (typeOperation === 'pagar') {
        setMsgConfirm('Yo ' + userAg.user_nombre + ' confirmo haber recibido la suma de $ ' + data.compra_valor);
      } else {
        setMsgConfirm('Yo ' + userAg.user_nombre + ' confirmo la compra del producto: ' + data.items[0].item_title);
      }
    }
  }, [data, open])

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h6" component="div" gutterBottom>
            Confirmacion de Pago.
          </Typography>
          <Box className="flex flex-row justify-between items-center mt-4">
            <Typography
              sx={{
                fontWeight: "medium",
                fontSize: "15px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "200px",
              }}
            >
              Contraseña
            </Typography>
            <Input
              type="password"
              placeholder="Contraseña"
              value={password}
              onChange={handlePasswordChange}
            />
          </Box>
          <Box className="flex flex-row justify-between items-center mt-4 mb-4">
            <Typography
              sx={{
                fontWeight: "medium",
                fontSize: "15px",
              }}
            >
                {msgConfirm}
            </Typography>
            <Checkbox
              checked={confirmation}
              onChange={handleConfirmationChange}
              color="primary"
            />
          </Box>
          <Box className="flex flex-row justify-center items-center">
            <Button
              sx={{
                backgroundColor: "#f9b22b",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#e09d21",
                },
                width: "220px",
                height: "50px",
              }}
              disabled={!confirmation || password === ''}
              onClick={handlePaidButtonClick}
            >
              {
                typeOperation === 'pagar' ? (
                  'Pagado'
                ) : (
                  'Comprado'
                )
              }
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default PopUp;
