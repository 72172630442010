import { Box, Button, CardMedia, CircularProgress, FormControl, InputLabel, MenuItem, Modal, Rating, Select, Stack, ThemeProvider, Typography, createTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import NavBar from "../main/navbar/Navbar";
import Dashboard from "../main/dashboard/Dashboard";
import RightBar from "../main/rightbar/RightBar";
import AddTooltip from "../main/addTooltip/AddTooltip";
import PPNavigation from "../main/ppNavigation/PPNavigation";
import { useLocation, useNavigate } from "react-router-dom";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SizeGuideModal from "../main/modal/SizeGuideModal";
import logoPrime from '../images/amazon_prime_icon.png';

const SaltexProductDetails = () => {

    const onLoading = () => {
      return (
        <Box className="w-screen h-screen flex items-center justify-center pb-32">
          <CircularProgress color="warning" />
        </Box>
      )
    }

    const initialProductDetails = {
      title: "",
      image: "",
      url: "",
      quantities: 1,
      priceUnitary: 0,
      priceTotal: 0
    };

    const [mode, setMode] = useState("light");
    const [isLoading, setIsLoading ] = useState(true)
    const [productDetail, setProductDetail] = useState([])
    const [imagesProduct, setImagesProduct] = useState([])
    const [selectedImage, setSelectedImage] = useState("")
    const [price, setPrice] = useState(0)
    const [quantity, setQuantity] = useState(1)
    const [productFormatted, setProductFormatted] = useState(initialProductDetails);
    const [selectedVariantAsin, setSelectedVariantAsin] = useState('');
    const [isSizeGuideOpen, setIsSizeGuideOpen] = useState(false);
    const [trm, setTrm] = useState(localStorage.getItem('trm'))
    
    const navigate = useNavigate()
    const APIKEY = process.env.REACT_APP_RAINFOREST_API;
    const location = useLocation();
    const product = location.state.product;
    const darkTheme = createTheme({
      palette:{
        mode: mode
      }
    });

    const fetchProductDetail = async (product) => {
      setIsLoading(true)
      try {
        const url = `https://api.rainforestapi.com/request?api_key=${APIKEY}&amazon_domain=amazon.com&type=product&asin=${product}`;
        //https://api.rainforestapi.com/request?api_key=49B58DDDA9914574B2E94830CAF6FFD1&amazon_domain=amazon.com&type=product&asin=B098PBJ379
        
        const response = await fetch(url);
        
        if (response.ok) {
          const data = await response.json();
          setProductDetail(data.product)
          console.log('fetchProductDetail')
          console.log(data.product.variants)
          console.log(data.product)
          setImagesProduct(data.product.images)
          setSelectedImage(data.product.main_image.link)
          setPrice(data.product.buybox_winner.price.value * 1.12)
          setIsLoading(false);
        } else {
          console.error('Error al obtener datos de la API.');
        }
      } catch (error) {
        console.error('Error al realizar la solicitud a la API:', error);
      }
    };

    const handleVariantChange = (event) => {
      const asin = event.target.value;
      setSelectedVariantAsin(asin);
      fetchProductDetail(asin);
    };
    
    const handleImageClick = (imageLink) => {
      setSelectedImage(imageLink);
    };

    const handleSendToCart = () => {
      // Obtén la lista actual de productos del localStorage
      const existingProducts = JSON.parse(localStorage.getItem("cart")) || [];

      // Agrega el nuevo producto a la lista
      const updatedProducts = [...existingProducts, productFormatted];

      // Guarda la lista actualizada en el localStorage
      localStorage.setItem("cart", JSON.stringify(updatedProducts));
      navigate('/cart', { state: { product: productFormatted } }); // Establece el producto seleccionado en Dashboard
    };

    const handleSizeGuideOpen = () => {
      setIsSizeGuideOpen(true);
    };
    
    const handleSizeGuideClose = () => {
      setIsSizeGuideOpen(false);
    };

    const hasShoeKeywords = (keywords) => {
      const shoeKeywords = ["Shoe", "Shoes", "Sneaker", "Sneakers"];
      return keywords.split(",").some(keyword => shoeKeywords.includes(keyword));
    };

    useEffect(() => {
      fetchProductDetail(product.asin);
    }, [product]);
    
    useEffect(() => {
      if (imagesProduct.length > 0 && selectedImage === "") {
        setSelectedImage(imagesProduct[0].link);
      }
    }, [imagesProduct]);

    useEffect(() => {
      if (productDetail && productDetail.title) {
        setProductFormatted({
          ...productFormatted,
          asin: productDetail.asin,
          title: productDetail.title,
          image: productDetail.main_image.link,
          url: productDetail.link,
          quantities: quantity,
          priceUnitary: (price * trm).toFixed(2),
          priceTotal: (price * trm * quantity).toFixed(2)
        });
      }
    }, [productDetail, price, trm, quantity]);
    

    return (
      <ThemeProvider theme={darkTheme}>
      <Box>
        <NavBar />
        {isLoading ? onLoading() : (
          <Box className="w-full h-full flex flex-col md:flex-row mt-4">
            <Box className="w-full flex flex-row md:w-1/2">
              <Box className="w-1/5 flex flex-col items-center justify-start">
                {imagesProduct.length > 0 ? (
                  imagesProduct.map((image, index) => (
                    <Box 
                    className="w-10 h-auto rounded-xl p-1 cursor-pointer"
                    key={index} m={2} onClick={() => handleImageClick(image.link)} 
                    sx={{
                      border: selectedImage === image.link ? '1px solid #F9B22B' : '1px solid gray'
                    }}>
                      <CardMedia
                        component="img"
                        image={image.link}
                        alt={`Imagen ${index}`}
                      />
                    </Box>
                  ))
                ) : (
                    <p>No hay imágenes disponibles</p> // O cualquier otro componente/mensaje que quieras mostrar
                )}
              </Box>
              <Box className="w-4/5 flex items-center justify-center">
                {selectedImage && (
                  <img className="w-auto h-96" src={selectedImage} alt="Imagen seleccionada" />
                )}
              </Box>
            </Box>
            <Box className="w-full flex flex-col md:w-1/2 md:flex-row">
              <Box className="w-full flex flex-col md:w-2/3 p-4" >
                <Box className="w-full flex flex-col items-center justify-center">
                  <Box className="w-full flex items-center justify-start">
                    <Typography className="cursor-pointer" fontSize={14} color={'#007185'} >Marca: {productDetail.brand}</Typography>
                  </Box>
                  <Box className="w-full flex items-center justify-start">
                    <Typography className="cursor-pointer" fontSize={20} >{productDetail.title}</Typography>
                  </Box>
                  <Box className="w-full flex items-center justify-start">
                    <Typography fontSize={14} >{productDetail.rating}</Typography>
                    <Rating className="ml-1 mr-1" name="read-only" value={productDetail.rating} precision={0.5} readOnly />
                    <Typography className="cursor-pointer" fontSize={14} color={'#007185'} >{productDetail.ratings_total}</Typography>
                  </Box>
                </Box>
                
                <Box className="w-full flex flex-col items-center justify-center border-t border-t-1 border-solid border-gray-300 mt-1">
                  <Box  className="w-full flex items-center justify-start mt-1">
                      <Box className="w-1/2 flex items-center justify-start">
                        <Typography fontSize={14}>Precio:</Typography>
                      </Box>
                      <Box className="w-1/2 flex items-center justify-start">
                        <Typography fontWeight={500} fontSize={18} color={'#B12704'}>${new Intl.NumberFormat('es-CO', { style: 'decimal', maximumFractionDigits: 0 }).format(price * trm)}</Typography>
                      </Box>
                  </Box>
                  <Box  className="w-full flex items-center justify-start mb-1">
                    { product.is_prime && <img className="w-12" alt="logo_prime"src={logoPrime}></img> }
                  </Box>
                  <Box className="w-full flex items-center justify-start">
                    <Typography fontSize={14} >* IVA: 19% para compras mayores a 200USD</Typography>
                  </Box>
                  <Box className="w-full flex items-center justify-start mt-4 mb-1">
                    <FormControl variant="outlined" className="w-full">
                      <InputLabel id="variant-label">Variantes</InputLabel>
                      <Select
                        labelId="variant-label"
                        id="variant-select"
                        value={selectedVariantAsin}
                        onChange={handleVariantChange}
                        label="Variantes"
                      >
                        {productDetail.variants.map((variant, index) => (
                          <MenuItem key={index} value={variant.asin}>
                            {variant.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box className="w-full flex items-center justify-start">
                  {productDetail.keywords && hasShoeKeywords(productDetail.keywords) && (
                      <Typography
                          onClick={handleSizeGuideOpen}
                          className="cursor-pointer"
                          fontSize={14}
                          color={'#007185'}
                      >
                          Guía de talles
                      </Typography>
                  )}
                  </Box>
                </Box>


                <Box className="w-full flex flex-col items-center justify-center border-t border-t-1 border-solid border-gray-300 mt-1">
                  <Box className="w-full flex items-center justify-start">
                    <Typography fontWeight={600} fontSize={20} >Detalles del producto</Typography>
                  </Box>
                  {
                    productDetail.specifications.slice(0, 10).map((detail, index) => (
                      <Box key={index} className="w-full flex items-center justify-start mt-1">
                        <Box className="w-1/2 flex items-center justify-start">
                          <Typography fontWeight={600} fontSize={14}>{detail.name}</Typography>
                        </Box>
                        <Box className="w-1/2 flex items-center justify-start">
                          <Typography fontSize={14}>{detail.value}</Typography>
                        </Box>
                      </Box>
                    ))
                  }
                </Box>
              </Box>
              <Box className="w-full h-full flex flex-col md:w-1/3 m-2 p-2 rounded-xl border border-1 border-solid border-gray-300" >
                <Box className="w-full flex items-center justify-start">
                  <Typography fontSize={14} >Entrega entre el: </Typography>
                </Box>
                <Box className="w-full flex items-center justify-start">
                  <Typography fontSize={14} fontWeight={600}>20 de diciembre - 9 de enero</Typography>
                </Box>
                <Box className="w-full flex items-center justify-start mt-1">
                    <LocationOnIcon />
                    <Typography className="cursor-pointer" fontSize={14} color={'#007185'} >Enviar a Colombia</Typography>
                </Box>
                <Box className="w-full flex items-center justify-start mt-4">
                  <FormControl variant="outlined" className="w-full">
                      <InputLabel id="quantity-label">Cantidad</InputLabel>
                      <Select
                          labelId="quantity-label"
                          id="quantity-select"
                          value={quantity}
                          onChange={(e) => setQuantity(e.target.value)}
                          label="Cantidad"
                      >
                          {[1, 2, 3, 4, 5].map((num) => (
                              <MenuItem key={num} value={num}>{num}</MenuItem>
                          ))}
                      </Select>
                  </FormControl>
                </Box>
                <Box  className="w-full flex items-center justify-start mt-4">
                  <Box className="w-1/2 flex items-center justify-start">
                    <Typography fontSize={14}>Precio total:</Typography>
                  </Box>
                  <Box className="w-1/2 flex items-center justify-start">
                    <Typography fontWeight={500} fontSize={18} color={'#B12704'}>${new Intl.NumberFormat('es-CO', { style: 'decimal', maximumFractionDigits: 0 }).format(price * trm * quantity)}</Typography>
                  </Box>
                </Box>
                <Box className="flex flex-col justify-center items-center mt-4">
                    <Button
                      onClick={handleSendToCart}
                      sx={{
                        backgroundColor: "#f9b22b",
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: "#e09d21",
                        },
                        margin: "0.5rem 0",
                      }}
                    >
                      Agregar al Carrito
                    </Button>
                    <Button
                      onClick={() => {
                        navigate("/Dashboard")
                      }}
                      sx={{
                        backgroundColor: "#f9b22b",
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: "#e09d21",
                        },
                        margin: "0.5rem 0",
                      }}
                    >
                      Seguir Comprando
                    </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      <SizeGuideModal
        isOpen={isSizeGuideOpen}
        handleClose={handleSizeGuideClose}
      />
      </ThemeProvider>
    );
}

export default SaltexProductDetails;