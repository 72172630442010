import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Input from '@mui/material/Input';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { ckeckAgPassword } from '../../services/orderService';
import DataTable from 'react-data-table-component';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const columns = [
    {
        name: 'Item',
        selector: row => row.item_link,
        width: '475px',
    },
    {
        name: 'Cantidad',
        selector: row => row.item_cantidad,
        maxWidth: '75px'
    },
    {
        name: 'Precio Unitario',
        selector: row => '$ ' + parseInt(row.item_price).toLocaleString(),
        maxWidth: '150px',
    },
];


const PopUpItems = ({ open, handleOpen, data}) => {

  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user_new")))
 
  const handleClose = () => handleOpen(false);

  useEffect(() => {

    setUser(JSON.parse(localStorage.getItem("user_new")))

    if (data && data.length) {
      if (user.user_id == 91 && user.agencia_id == 1) {
        for(let i=0; i<data.length; i++){
          let linkCell = <a href={data[i].item_url} target='blank'>{data[i].item_title}</a>;
          data[i].item_link = linkCell;
        }
      } else {
        for(let i=0; i<data.length; i++){
          let linkCell = <p>{data[i].item_title}</p>;
          data[i].item_link = linkCell;
        }
      }
    }

    }, [data, open])

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h6" component="div" gutterBottom>
            Lista de Productos.
          </Typography>
          <Typography variant="h10" component="div" gutterBottom>
            Precios sin IVA y sin Impuesto USA*
          </Typography>
          <Box className="flex flex-row justify-between items-center mt-4">
          <DataTable
            columns={columns}
            data={data}
          />
          </Box>
          <Typography className='pt-6' sx={{fontSize: '10px'}} component="div" gutterBottom>
            * Impuesto USA: 7% para compras mayores a 200USD
          </Typography>
          
        </Box>
      </Modal>
    </div>
  );
};

export default PopUpItems;
