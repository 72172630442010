const API_URL = process.env.REACT_APP_BE_API_EWA;

export const getUsers = () => {
    console.log('getUsers')
    const userDataString = localStorage.getItem('user_new');
    
    if (!userDataString) {
        // Manejar el caso en el que no hay datos de usuario en el almacenamiento local
        console.error('No hay datos de usuario en el almacenamiento local');
        return;
    }

    // Convertir la cadena JSON a un objeto JavaScript
    const userDataArray = JSON.parse(userDataString);

    // userDataArray es un array, supongamos que solo hay un elemento en el array
    const userData = userDataArray;

    // Ahora puedes acceder a cli_id y cuenta_id
    const agId = userData.agencia_id;

    const authData = {
        ageId: agId,
    };

    console.log(authData)

    return new Promise(function (resolve, reject) {
        fetch(API_URL + 'user.php/user/listUsersByAg', {
            method: 'POST',
            body: JSON.stringify(authData),
        }).then((response) => {
            let data = response.json()
            resolve(data);
        });
    });
};

export const postUsers = (user) => {
    console.log('postUsers')

    const authData = {
        cliId : user.cli_id,
        clinombre : user.Cli_nombre,
        cliemail : user.cli_email,
        clitel : user.cli_tel
    };

    console.log(authData)

    return new Promise(function (resolve, reject) {
        fetch(API_URL + 'user.php/user/updateUser', {
            method: 'POST',
            body: JSON.stringify(authData),
        }).then((response) => {
            let data = response.json()
            resolve(data);
        });
    });
};

export const postEmailUser = (user) => {
    console.log('postUsers')
    const userDataString = localStorage.getItem('user_new');
    
    if (!userDataString) {
        // Manejar el caso en el que no hay datos de usuario en el almacenamiento local
        console.error('No hay datos de usuario en el almacenamiento local');
        return;
    }

    // Convertir la cadena JSON a un objeto JavaScript
    const userDataArray = JSON.parse(userDataString);

    // userDataArray es un array, supongamos que solo hay un elemento en el array
    const userData = userDataArray;

    // Ahora puedes acceder a cli_id y cuenta_id
    const userId = userData.user_id;

    const authData = {
        cliId : userId,
    };

    console.log(authData)

    return new Promise(function (resolve, reject) {
        fetch(API_URL + 'user.php/user/emailcli', {
            method: 'POST',
            body: JSON.stringify(authData),
        }).then((response) => {
            let data = response.json()
            resolve(data);
        });
    });
};

