import {
    Box,
    Button,
    Typography,
    ListItem,
    ListItemText,
    CircularProgress,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { postOrder } from "../../services/orderService";
import Swal from 'sweetalert2';

  const onLoading = () => {
    return (
      <Box className="w-full h-full flex items-center justify-center mt-4">
        <CircularProgress color="warning" />
      </Box>
    )
  }

  const ProductDetails = ({ products, quantities, handleDecrease, handleIncrease, handleRemove  }) => {
    useEffect(() => {
      console.log('ProductDetails')
      console.log(products)
    }, [products])
    return (
      <Box>
        {products.map((product, index) => (
          <Box
            key={index}
            id={`cardDetailsProduct-${index}`}
            className="flex flex-row justify-around items-center h-full w-full border border-gray-300 rounded  pt-4 pb-4 mb-4"
          >
            <Box>
              <img
                src={
                  !product.image
                    ? "Imagen no disponible"
                    : product.image
                }
                alt={product.title}
                className="w-20 h-auto mb-2"
              />
            </Box>
            <Box>
              {/* <Link
                href={product.link}
                target="_blank"
                rel="noopener noreferrer"
              > */}
                <Typography
                  variant="h1"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "17px",
                    marginBottom: "0.5rem",
                    cursor: "pointer",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "150px",
                    textDecoration: "underline",
                  }}
                >
                  {product.title}
                </Typography>
              {/* </Link> */}
              <Typography
                sx={{
                  fontSize: "13px",
                  marginBottom: "0.5rem",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  maxWidth: "200px",
                }}
              >
                Nuevo
              </Typography>
            </Box>
            <Box className="flex flex-col justify-center items-center">
              <Box className="flex flex-row justify-between items-center w-20">
                <Button
                  onClick={() => handleDecrease(index)}
                  sx={{
                    borderRadius: "50%",
                    border: "1px solid black",
                    color: "black",
                    width: "20px",
                    height: "20px",
                    minWidth: "unset",
                    padding: "0",
                  }}
                >
                  -
                </Button>
                <Typography>{product.quantities}</Typography>
                <Button
                  onClick={() => handleIncrease(index)}
                  sx={{
                    borderRadius: "50%",
                    border: "1px solid black",
                    color: "black",
                    width: "20px",
                    height: "20px",
                    minWidth: "unset",
                    padding: "0",
                  }}
                >
                  +
                </Button>
              </Box>
              <Typography
                sx={{
                  fontWeight: "medium",
                  fontSize: "15px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  maxWidth: "200px",
                }}
              >
                $ {/* {(product.priceUnitary * product.quantities).toLocaleString()} */}
                {new Intl.NumberFormat('es-CO', { style: 'decimal', maximumFractionDigits: 0 }).format(product.priceUnitary * product.quantities)}
              </Typography>
              <Button
                onClick={() => handleRemove(index)}
                sx={{
                  backgroundColor: "transparent",
                  border: "1px solid #ff0000", // Color rojo
                  color: "#ff0000", // Color rojo
                  "&:hover": {
                    backgroundColor: "transparent",
                    borderColor: "#cc0000", // Color rojo más oscuro al pasar el mouse
                    color: "#cc0000", // Color rojo más oscuro al pasar el mouse
                  },
                  width: "16px", // Ancho más pequeño
                  height: "16px", // Altura más pequeña
                  fontSize: "10px",
                }}
              >
                Eliminar
              </Button>
            </Box>
          </Box>
        ))}
      </Box>
    );
  };

  const AddressForm = ({ cartProducts }) => {

    const navigate = useNavigate()
    const [send, setSend] = useState(0);
    const [trm, setTrm] = useState(localStorage.getItem('trm'))
  
    // Ensure quantities and cartProducts are defined
    if (!cartProducts) {
      // Handle the case where quantities or cartProducts is undefined
      return <div>Error: Quantities or cartProducts is undefined</div>;
    }

    const calculateTotalQuantity = (cartProducts) => {
      return cartProducts.reduce((total, product) => total + product.quantities, 0);
    };

    const calculateTotalPrice = (cartProducts) => {
      return cartProducts.reduce((total, product) => total + parseFloat(product.priceTotal), 0);
    };

    const calculateUsaTax = (totalPrice) => {
      const usaTaxRate = 0.07; // 7%
      return (totalPrice * usaTaxRate).toFixed(2);
    };

    const calculateIva = (totalPrice) => {
      const ivaRate = 0.19; // 19%
      if ((totalPrice / trm) >= 200) {
        return (totalPrice * ivaRate).toFixed(2);
      }
      return 0;
    };
    

    const calculateSubtotal = (totalPrice, usaTax, iva) => {
      return (parseFloat(totalPrice) + parseFloat(usaTax) + parseFloat(iva)).toFixed(2);
    };

    const totalQuantity = calculateTotalQuantity(cartProducts);
    const totalPrice = calculateTotalPrice(cartProducts).toFixed(2);
    const usaTax = calculateUsaTax(totalPrice);
    const iva = calculateIva(totalPrice);
    const subtotal = calculateSubtotal(totalPrice, usaTax, iva);
  
    const sendOrder = () => {
      if(totalPrice>0){
        postOrder(subtotal, totalQuantity, cartProducts).then((response) => {
          console.log(response)
          if (response.status === 200) {
            Swal.fire({
              title: 'Excelente!',
              text: 'Su pedido fue realizado exitosamente.',
              icon: 'success',
              confirmButtonText: 'Ok!'
            })
            localStorage.removeItem('cart');
            navigate('/dashboard');
          } else {
            Swal.fire({
              title: 'Upps!',
              text: 'Su carrito esta vacio.',
              icon: 'error',
              confirmButtonText: 'Ok!'
            })
          }
        })
      } 
    }

    return (
      <Box className="flex flex-col justify-around items-center h-full w-full">
        <Box className="flex flex-col justify-center items-center w-full">
          <Box className="flex flex-row justify-between items-center w-2/3 mt-4">
            <Typography
              sx={{
                fontWeight: "medium",
                fontSize: "13px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "200px",
              }}
            >
              Articulos
            </Typography>
            <Typography
              sx={{
                fontWeight: "medium",
                fontSize: "13px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "200px",
              }}
            >
              ({totalQuantity})
            </Typography>
          </Box>
          <Box className="flex flex-row justify-between items-center w-2/3 mt-4">
            <Typography
              sx={{
                fontWeight: "medium",
                fontSize: "13px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "200px",
              }}
            >
              Prec. s/imp.
            </Typography>
            <Typography
              sx={{
                fontWeight: "medium",
                fontSize: "13px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "200px",
              }}
            >
              $ {/* {totalPrice} */} {new Intl.NumberFormat('es-CO', { style: 'decimal', maximumFractionDigits: 0 }).format(totalPrice)}
            </Typography>
          </Box>
          <Box className="flex flex-row justify-between items-center w-2/3 mt-4">
            <Typography
              sx={{
                fontWeight: "medium",
                fontSize: "13px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "200px",
              }}
            >
            Taxes USA 7%:
            </Typography>
            <Typography
              sx={{
                fontWeight: "medium",
                fontSize: "13px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "200px",
              }}
            >
              $ {new Intl.NumberFormat('es-CO', { style: 'decimal', maximumFractionDigits: 0 }).format(usaTax)}
            </Typography>
          </Box>
          <Box className="flex flex-row justify-between items-center w-2/3 mt-4">
            <Typography
              sx={{
                fontWeight: "medium",
                fontSize: "13px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "200px",
              }}
            >
            IVA 19%:
            </Typography>
            <Typography
              sx={{
                fontWeight: "medium",
                fontSize: "13px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "200px",
              }}
            >
              $ {/* {iva} */} {new Intl.NumberFormat('es-CO', { style: 'decimal', maximumFractionDigits: 0 }).format(iva)}
            </Typography>
          </Box>
          <Box className="flex flex-row justify-between items-center w-2/3 mt-4">
            <Typography
              sx={{
                fontWeight: "medium",
                fontSize: "13px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "200px",
              }}
            >
              Subtotal
            </Typography>
            <Typography
              sx={{
                fontWeight: "medium",
                fontSize: "13px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "200px",
              }}
            >
              $ {/* {subtotal} */} {new Intl.NumberFormat('es-CO', { style: 'decimal', maximumFractionDigits: 0 }).format(subtotal)}
            </Typography>
          </Box>
        </Box>
        <Box className="flex justify-center items-center w-2/3 mt-4"> 
            <Button
              onClick={sendOrder}
              sx={{
                backgroundColor: "#f9b22b",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#e09d21",
                },
                width: "220px",
                height: "50px",
              }}
            >
              Completar Compra
            </Button>
        </Box>
        <Box className="flex justify-center items-center w-2/3 mt-4 mb-4">
          <Button
            onClick={() => {
              navigate("/Dashboard")
            }}
            sx={{
              backgroundColor: "#f9b22b",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#e09d21",
              },
              width: "220px",
              height: "50px",
            }}
          >
            Seguir Comprando
          </Button>
        </Box>
      </Box>
    );
  };

  const UserInfo = () => {
    const userData = JSON.parse(localStorage.getItem('user_new'));
    return (
      <Box className="flex flex-col justify-around items-center h-full w-full border border-gray-300 rounded">
        <Box className="flex flex-col justify-center items-center w-full">
        <Box className="flex flex-row justify-between items-center w-5/6 mt-4">
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "14px",
                marginBottom: "0.5rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "200px",
                textDecoration: "underline",
              }}
            >
              Datos del Receptor
            </Typography>
          </Box>
          <Box className="flex flex-row justify-between items-center w-5/6 mt-4">
            <Typography
              sx={{
                fontWeight: "medium",
                fontSize: "13px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "200px",
              }}
            >
              Nombre: 
            </Typography>
            <Typography
              sx={{
                fontWeight: "medium",
                fontSize: "13px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "200px",
              }}
            >
              {userData.cli_nombre}
            </Typography>
          </Box>
          <Box className="flex flex-row justify-between items-center w-5/6 mt-4">
            <Typography
              sx={{
                fontWeight: "medium",
                fontSize: "13px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "250px",
              }}
            >
              Dir. de Entrega:
            </Typography>
            <Typography
              sx={{
                fontWeight: "medium",
                fontSize: "13px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "300px",
              }}
            >
              {userData.cuenta_direccion}
            </Typography>
          </Box>
          <Box className="flex flex-row justify-between items-center w-5/6 mt-4">
            <Typography
              sx={{
                fontWeight: "medium",
                fontSize: "13px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "250px",
              }}
            >
              Ciudad:
            </Typography>
            <Typography
              sx={{
                fontWeight: "medium",
                fontSize: "13px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "300px",
              }}
            >
              {userData.cuenta_cuidad} - {userData.cuenta_pais}
            </Typography>
          </Box>
          <Box className="flex flex-row justify-between items-center w-5/6 mt-4 mb-4">
            <Typography
              sx={{
                fontWeight: "medium",
                fontSize: "13px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "250px",
              }}
            >
              Telefono:
            </Typography>
            <Typography
              sx={{
                fontWeight: "medium",
                fontSize: "13px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "300px",
              }}
            >
              {userData.cli_tel}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  const SendInfo = () => {
    return (
      <Box className="flex flex-col justify-center items-center h-full w-full border border-red-400 rounded mt-4">
          <Box className="flex flex-row justify-between items-center w-5/6 mt-4">
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "14px",
                marginBottom: "0.5rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "200px",
                textDecoration: "underline",
              }}
            >
              Envio
            </Typography>
          </Box>
          <Box className="flex flex-col justify-center items-center w-5/6 mt-2 mb-2">
            <Typography
              sx={{
                fontWeight: "medium",
                fontSize: "13px",
                whiteSpace: "normal",
              }}
            >
              El valor del envio sera pagado cuando la mercancia llegue a la agencia.
            </Typography>
            <Typography
              sx={{
                fontWeight: "medium",
                fontSize: "13px",
                whiteSpace: "normal",
              }}
            >
              Su valor aproximado es entre $70.000 y $100.000.
            </Typography>
          </Box>
      </Box>
    );
  };
  
  const Cart = () => {

    const [cartProducts, setCartProducts] = useState([]);
    const [quantities, setQuantities] = useState(0);

    useEffect(() => {
      const cartData = localStorage.getItem("cart");
      const parsedCartData = cartData ? JSON.parse(cartData) : [];
      setCartProducts(parsedCartData)
    }, []);

    const handleDecrease = (index) => {
      // Crear una copia del array actual de productos
      let newCartProducts = [...cartProducts];
    
      // Disminuir la cantidad del producto específico, asegurando que no sea menor que 1
      if (newCartProducts[index].quantities > 1) {
        newCartProducts[index].quantities -= 1;
      }
    
      // Actualizar el precio total del producto
      newCartProducts[index].priceTotal = (newCartProducts[index].priceUnitary * newCartProducts[index].quantities).toFixed(2);
    
      // Actualizar el estado con el nuevo array de productos
      setCartProducts(newCartProducts);
    };

    const handleIncrease = (index) => {
      // Crear una copia del array actual de productos
      let newCartProducts = [...cartProducts];
    
      // Aumentar la cantidad del producto específico
      newCartProducts[index].quantities += 1;
    
      // Actualizar el precio total del producto
      newCartProducts[index].priceTotal = (newCartProducts[index].priceUnitary * newCartProducts[index].quantities).toFixed(2);
    
      // Actualizar el estado con el nuevo array de productos
      setCartProducts(newCartProducts);
    };

    const handleRemove = (index) => {
      const newCartProducts = [...cartProducts];
      newCartProducts.splice(index, 1); // Eliminar el producto en la posición index
      setCartProducts(newCartProducts);
      setQuantities(newCartProducts.map(() => 1));
      localStorage.setItem("cart", JSON.stringify(newCartProducts));
    };

    return (
      <Box className="w-full h-full flex flex-col justify-start items-center mt-10" >
        <Typography variant="h4">Carrito de Compras</Typography>
        <Box className="flex flex-col items-start justify-center w-full md:flex-row md:w-full">
          <Box className="w-full m-2 h-auto md:w-1/4">
            <UserInfo />
            <SendInfo />
          </Box>
          <Box className="w-full m-2 h-full md:w-2/4 overflow-y-auto max-h-[500px]">
            <ProductDetails
            products={cartProducts}
            quantities={quantities}
            handleDecrease={handleDecrease}
            handleIncrease={handleIncrease}
            handleRemove={handleRemove} />
          </Box>
          <Box className="w-full m-2 h-auto border border-gray-300 rounded md:w-1/4">
            <AddressForm cartProducts={cartProducts} />
          </Box>
        </Box>
      </Box>
    );
  };
  
export default Cart;
  