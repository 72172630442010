const API_URL = process.env.REACT_APP_BE_API_EWA;

export const getPopUp = () => {
    console.log('getPopUp')

    const authData = {
        popupid: '1',
    };

    console.log(authData)

    return new Promise(function (resolve, reject) {
        fetch(API_URL + 'popup.php/popup/getPopUp', {
            method: 'POST',
            body: JSON.stringify(authData),
        }).then((response) => {
            let data = response.json()
            resolve(data);
        });
    });
};

export const postPopUp = (state, type, url) => {
    console.log('PostPopUp')

    const authData = {
        popupid: 1,
        popupactiva: state,
        popuptype: type,
        popupurl: url
    };

    console.log(authData)

    return new Promise(function (resolve, reject) {
        fetch(API_URL + 'popup.php/popup/updatePopUp', {
            method: 'POST',
            body: JSON.stringify(authData),
        }).then((response) => {
            resolve(response);
        });
    });
};

