import { Box, Fab, Modal, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Add as  AddIcon } from '@mui/icons-material';
import styled from "@emotion/styled";
import TextField from '@mui/material/TextField'

const StyledModal = styled(Modal)({
    display:"flex",
    alignItems:"center",
    justifyContent:"center"
});

const AddTooltip = () =>{

    const [open, setOpen] = useState(false);
    let userAgString = localStorage.getItem('user_new');
    let userAg = JSON.parse(userAgString);
    const url = `https://lobby.impexpress.com/registro.php?sma=${userAg.agencia_id}`;

    useEffect(() => {
        console.log(userAg.agencia_id)
    }, [])
    return(
        <>
            <Tooltip onClick={(e)=>setOpen(true)} title="Add User" sx={{position:"fixed", bottom:20, left:{xs:"calc(50%)", md:30}}}>
                <Fab color="primary" aria-label="add">
                    <AddIcon />
                </Fab> 
            </Tooltip>
            <StyledModal
                open={open}
                onClose={(e)=>setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <Box width={600} height={900} bgcolor="white" p={3} borderRadius={5}>
                <iframe
                title="Registro"
                src={url}
                width="100%"
                height="100%"
                />
                </Box>
            </StyledModal>
        </>
    )
}

export default AddTooltip;