const API_URL = process.env.REACT_APP_BE_API_EWA;

export const postOrder = (totalPrice, itemsQuantity, itemsProducts) => {
    console.log('postOrder')
    console.log(itemsProducts)
    const userDataString = localStorage.getItem('user_new');
    
    if (!userDataString) {
        // Manejar el caso en el que no hay datos de usuario en el almacenamiento local
        console.error('No hay datos de usuario en el almacenamiento local');
        return;
    }

    // Convertir la cadena JSON a un objeto JavaScript
    const userDataArray = JSON.parse(userDataString);

    // userDataArray es un array, supongamos que solo hay un elemento en el array
    const userData = userDataArray;

    // Ahora puedes acceder a cli_id y cuenta_id
    const cliId = userData.cli_id;
    const cuentaId = userData.cuenta_id;

    const authData = {
        usuario_id: cliId,
        cuenta_id: cuentaId,
        compra_valor: totalPrice,
        compra_items: []
    };

    // Iterar sobre cada producto en itemsProducts
    itemsProducts.forEach(product => {
        const compraItem = {
            item_cantidad: product.quantities,
            item_url: `https://www.amazon.com/dp/${product.asin}?linkCode=ll1&tag=ewastore-20&language=en_US`,
            item_title: product.title,
            item_price: product.priceTotal
        };

        // Agregar el objeto compraItem al array compra_items
        authData.compra_items.push(compraItem);
    });

    console.log(authData)

    return new Promise(function (resolve, reject) {
        fetch(API_URL + 'shopping.php/shopping/addUserShop', {
            method: 'POST',
            body: JSON.stringify(authData),
        }).then((response) => {
            resolve(response);
        });
    });
};

export const postAgOrder = () => {

    const userDataString = localStorage.getItem('user_new');

    if (!userDataString) {
        // Manejar el caso en el que no hay datos de usuario en el almacenamiento local
        console.error('No hay datos de usuario en el almacenamiento local');
        return;
    }

    // Convertir la cadena JSON a un objeto JavaScript
    const userDataArray = JSON.parse(userDataString);

    // userDataArray es un array, supongamos que solo hay un elemento en el array
    const userData = userDataArray;

    // Ahora puedes acceder a cli_id y cuenta_id
    const cuentaId = userData.agencia_id;

    const authData = {
      cuenta_id: cuentaId,
    }

   
    return new Promise(function (resolve, reject) {
        fetch(API_URL + 'shopping.php/shopping/listShoppingByAg',{
            method: 'POST',
            body: JSON.stringify(authData),
        }).then((response) => {
            return response.json();
        })
        .then((jsonData) => {
            resolve(jsonData);
        });
    });
}

export const postCliOrder = () => {

    const userDataString = localStorage.getItem('user_new');

    if (!userDataString) {
        // Manejar el caso en el que no hay datos de usuario en el almacenamiento local
        console.error('No hay datos de usuario en el almacenamiento local');
        return;
    }

    // Convertir la cadena JSON a un objeto JavaScript
    const userDataArray = JSON.parse(userDataString);

    // userDataArray es un array, supongamos que solo hay un elemento en el array
    const userData = userDataArray;

    // Ahora puedes acceder a cli_id y cuenta_id
    const cliId = userData.cli_id;

    const authData = {
        cli_id: cliId,
    }
   
    return new Promise(function (resolve, reject) {
        fetch(API_URL + 'shopping.php/shopping/listShoppingByCli',{
            method: 'POST',
            body: JSON.stringify(authData),
        }).then((response) => {
            return response.json();
        })
        .then((jsonData) => {
            resolve(jsonData);
        });
    });
}

export const postAdmOrder = () => {

    const userDataString = localStorage.getItem('user_new');

    if (!userDataString) {
        // Manejar el caso en el que no hay datos de usuario en el almacenamiento local
        console.error('No hay datos de usuario en el almacenamiento local');
        return;
    }

    // Convertir la cadena JSON a un objeto JavaScript
    const userDataArray = JSON.parse(userDataString);

    // userDataArray es un array, supongamos que solo hay un elemento en el array
    const userData = userDataArray;

    // Ahora puedes acceder a cli_id y cuenta_id
    const cuentaId = userData.cuenta_id;

    const authData = {
      cuenta_id: cuentaId,
    }
   
    return new Promise(function (resolve, reject) {
        fetch(API_URL + 'shopping.php/shopping/listShoppingByAdmin',{
            method: 'POST',
            body: JSON.stringify(authData),
        }).then((response) => {
            return response.json();
        })
        .then((jsonData) => {
            resolve(jsonData);
        });
    });
}

export const ckeckAgPassword = (password, compra_id) => {
    console.log('ckeckAgPassword')
    const userDataString = localStorage.getItem('user_new');

    if (!userDataString) {
        // Manejar el caso en el que no hay datos de usuario en el almacenamiento local
        console.error('No hay datos de usuario en el almacenamiento local');
        return;
    }

    // Convertir la cadena JSON a un objeto JavaScript
    const userDataArray = JSON.parse(userDataString);

    // userDataArray es un array, supongamos que solo hay un elemento en el array
    const userData = userDataArray;

    // Ahora puedes acceder a cli_id y cuenta_id
    const cuentaId = userData.agencia_id;

    const authData = {
      user_id: cuentaId,
      password: password,
      compra_id: compra_id,
    }

    console.log(authData)
   
    return new Promise(function (resolve, reject) {
        fetch(API_URL + 'user.php/user/ckeckAgPassword',{
            method: 'POST',
            body: JSON.stringify(authData),
        }).then((response) => {
            return response.json();
        })
        .then((jsonData) => {
            resolve(jsonData);
        });
    });
}

export const updateToComprada = (password, compra_id) => {
    console.log('updateToComprada')
    const userDataString = localStorage.getItem('user_new');

    if (!userDataString) {
        // Manejar el caso en el que no hay datos de usuario en el almacenamiento local
        console.error('No hay datos de usuario en el almacenamiento local');
        return;
    }

    // Convertir la cadena JSON a un objeto JavaScript
    const userDataArray = JSON.parse(userDataString);

    // userDataArray es un array, supongamos que solo hay un elemento en el array
    const userData = userDataArray;

    // Ahora puedes acceder a cli_id y cuenta_id
    const cuentaId = userData.user_id;

    const authData = {
      user_id: cuentaId,
      compra_id: compra_id,
    }

    console.log(authData)
   
    return new Promise(function (resolve, reject) {
        fetch(API_URL + 'user.php/user/updateToComprada',{
            method: 'POST',
            body: JSON.stringify(authData),
        }).then((response) => {
            return response.json();
        })
        .then((jsonData) => {
            console.log(jsonData)
            resolve(jsonData);
        });
    });
}
