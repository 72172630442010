import React, { useEffect, useState } from 'react';
import { Box, ThemeProvider, createTheme, FormControl, InputLabel, Select, MenuItem, TextField, Button, Typography } from "@mui/material";
import NavBar from "../main/navbar/Navbar";
import PPNavigation from "../main/ppNavigation/PPNavigation";
import { getPopUp, postPopUp } from '../services/popUpService';
import Swal from 'sweetalert2';

const SaltexListPopUp = () => {
  const [mode, setMode] = useState("light");
  const [state, setState] = useState('');
  const [type, setType] = useState('');
  const [url, setUrl] = useState('');

  const handleEstadoChange = (event) => {
    setState(event.target.value);
  };

  const handleTipoChange = (event) => {
    setType(event.target.value);
  };

  const handleUrlChange = (event) => {
    setUrl(event.target.value);
  };

  const changePopUp = () => {
    postPopUp(state, type, url).then((response) => {
      console.log('changePopUp')
      console.log(response)

      if (response.status === 200) {
        Swal.fire({
          title: 'Excelente!',
          text: 'Su configuracion se ha guardado correctamente.',
          icon: 'success',
          confirmButtonText: 'Ok!'
        })
      }
    })
  }

  useEffect(() => {
    getPopUp().then((response) => {
      console.log('getPopUp en listpopup');
      console.log(response);
  
      // Suponiendo que response ya es el objeto JSON deseado
      // y contiene al menos un elemento
      if (response && response.length > 0) {
        const popupData = response[0];
        setState(popupData.popup_activa);
        setType(popupData.popup_type);
        setUrl(popupData.popup_url);
      }
    });
  }, []);

  const darkTheme = createTheme({
    palette: {
      mode: mode
    }
  });

  return (
    <ThemeProvider theme={darkTheme}>
      <Box className="h-full">
        <NavBar />
        <Box className="w-full flex h-full">
          <PPNavigation />
          <Box className="w-full  h-full flex flex-col justify-center items-center">
            <Box className="w-2/3 flex flex-col justify-center items-center mt-4">
              <Box className="w-1/3 flex flex-col justify-center items-center">
                <Typography fontSize={20} >Configurar Pop Up</Typography>
              </Box>
            </Box>
            <Box className="w-2/5 h-full flex flex-col justify-center items-center mt-4" >
              <FormControl variant="outlined" sx={{ m: 1, width: 300 }}>
                <InputLabel>Estado</InputLabel>
                <Select
                  value={state}
                  onChange={handleEstadoChange}
                  label="Estado"
                >
                  <MenuItem value={1}>Activo</MenuItem>
                  <MenuItem value={0}>Inactivo</MenuItem>
                </Select>
              </FormControl>
              <FormControl variant="outlined" sx={{ m: 1, width: 300 }}>
                <InputLabel>Tipo</InputLabel>
                <Select
                  labelId="variant-label"
                  value={type}
                  onChange={handleTipoChange}
                  label="Tipo"
                >
                  <MenuItem value={1}>Video</MenuItem>
                  <MenuItem value={2}>Imagen</MenuItem>
                </Select>
              </FormControl>
              <TextField
                labelId="variant-label"
                label="URL"
                variant="outlined"
                sx={{ m: 1, width: 300 }}
                value={url}
                onChange={handleUrlChange}
              />
              <Button
                sx={{
                  backgroundColor: "#f9b22b",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#e09d21",
                  },
                  width: "300px",
                  height: "50px",
                }}
                onClick={() => changePopUp()}
              >Guardar Cambios</Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default SaltexListPopUp;
